// @ts-strict-ignore
import constants from "./utils/constants";

const initSentry = async () => {
	try {
		const { setTag, init, Replay } = await import("@sentry/react");
		const { BrowserTracing } = await import("@sentry/tracing");

		switch (process.env.REACT_APP_DOMAIN) {
		case constants.STAGING_REACT_APP_DOMAIN:
			init({
				dsn: "https://7344df08103b4d039eecdd7d915c2df4@o4504722897829888.ingest.sentry.io/4504750926725120",
				integrations: [new BrowserTracing()],
		
				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,
				environment: "Staging",
				release: "STAGING",
				ignoreErrors: [
					/.*appVersion.*/
				]
			});
			setTag("environment_name", "Staging");
		
			break;
		case constants.QA_REACT_APP_DOMAIN:
			init({
				dsn: "https://c1b63796238e42f9b9cbd298d6ebc120@o4504722897829888.ingest.sentry.io/4504722904645632",
				integrations: [new BrowserTracing()],
		
				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,
				environment: "QA",
				release: "QA",
				ignoreErrors: [
					/.*appVersion.*/
				]
			});
			setTag("environment_name", "QA");
		
			break;
		case constants.PROD_REACT_APP_DOMAIN:
			init({
				dsn: "https://23c2f804b4574c9ca5dc747458576682@o4504722897829888.ingest.sentry.io/4504881019420672",
				integrations: [new BrowserTracing(), new Replay({ maskAllText: false, maskAllInputs: false, blockAllMedia: false })],
		
				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,
				// If the entire session is not sampled, use the below sample rate to sample
				// sessions when an error occurs.
				replaysOnErrorSampleRate: 1.0,
				environment: "Production",
				release: "PRODUCTION",
				ignoreErrors: [
					/.*appVersion.*/
				]
			});
			setTag("environment_name", "Production");
		
			break;
		default:
			break;
		}
	} catch (error) {
		console.log("Error initializing sentry:", error);
	}
};

export default initSentry;
