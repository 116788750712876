import axios, { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import * as Sentry from "@sentry/react";

import getServerURL from "../getServerURL/getServerURL";
import { logOutUserWithSessionIssues } from "../userLogOutUtils";

const refreshTokenRequest = () : Promise<AxiosResponse> => {
	return new Promise((resolve, reject) => {
		const serverUrl = getServerURL().replace("/graphql", "");
		
		axios.get(`${serverUrl}/refresh-token`, {
			withCredentials: true
		}).then((res) => {
			const decoded = jwt.decode(res.data);

			if (!decoded) return reject(new Error("Invalid token"));
			
			return resolve(res);
		}).catch((err) => {
			Sentry.captureException(`Error when refreshing token - ${err}`);
			logOutUserWithSessionIssues();

			return reject(err);
		});
	});
};

export default refreshTokenRequest;
