// @ts-strict-ignore
import {
	useEffect,
	useMemo
} from "react";
import { StatsigClient } from "@statsig/js-client";
import { getStatsigEnvironment } from "app/utils/getEnvironment";

import {
	UserProp
} from "app/GraphqlGeneratedTypes/types";

const useStatsigClient = (activeUser: UserProp): StatsigClient => {
	const statsigClient = useMemo(() => {
		const client = new StatsigClient(
			process.env.REACT_APP_STATSIG_CLIENT_API_KEY,
			{},
			{ environment: { tier: getStatsigEnvironment() } }
		);

		client.initializeAsync();

		return client;
	}, []);

	useEffect(() => {
		if (!statsigClient) {
			return;
		}

		const statsigUser = activeUser
			? {
				userID: activeUser?.publicId,
				email: activeUser?.email,
				custom: {
					roles: activeUser?.roles?.map((role) => role.name).join(","),
					companyPublicId: activeUser?.company?.publicId,
					rights: activeUser?.rights
				},
				appVersion: sessionStorage.getItem("appVersion")
			}
			: {};

		statsigClient.updateUserAsync(statsigUser);
	}, [statsigClient, activeUser]);

	return statsigClient;
};

export default useStatsigClient;
