// @ts-strict-ignore
let historyObject;

const initializeHistory = ({ history }) => {
	historyObject = history;
};

const logOutUserWithSessionIssues = () => {
	localStorage.removeItem("dcbyte-jwt");
	historyObject.push("/login");
};

export {
	initializeHistory,
	logOutUserWithSessionIssues
};
