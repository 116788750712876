// @ts-strict-ignore
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "../reducers/root__reducer";
import { isProductionBuild } from "app/utils/getEnvironment";

export default function configureStore(initialState) {
	const enhancers = [applyMiddleware(reduxThunk)];

	// redux dev tools and redux persist to run client side only:
	if (
		isProductionBuild() &&
		window.__REDUX_DEVTOOLS_EXTENSION__
	) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const store = createStore(reducers, initialState, compose(enhancers[0]));

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("../reducers/root__reducer", () =>
			store.replaceReducer(require("../reducers/root__reducer"))
		);
	}

	return store;
}
