// @ts-strict-ignore
import constants from "../constants";

const getServerURL = () : string => {
	let httpLinkUri: string;
	if (process.env.NODE_ENV === constants.PROD_NODE_ENV && process.env.REACT_APP_DOMAIN === constants.STAGING_REACT_APP_DOMAIN) {
		httpLinkUri = "https://server.staging.dcbyte.com/graphql";
	} else if (process.env.NODE_ENV === constants.PROD_NODE_ENV && process.env.REACT_APP_DOMAIN === constants.PROD_REACT_APP_DOMAIN) {
		httpLinkUri = "https://server.app.dcbyte.com/graphql";
	} else if (process.env.NODE_ENV === constants.PROD_NODE_ENV
		&& process.env.REACT_APP_DOMAIN !== constants.STAGING_REACT_APP_DOMAIN
		&& process.env.REACT_APP_DOMAIN !== constants.PROD_REACT_APP_DOMAIN) {
		httpLinkUri = "/graphql";
	} else if (process.env.NODE_ENV === constants.DEV_NODE_ENV) {
		httpLinkUri = "http://localhost:8208/graphql";
	}

	return httpLinkUri;
};

export default getServerURL;
