// @ts-strict-ignore
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as ApolloV2 from "@apollo/client";
import { Provider } from "react-redux";
import StyleContext from "isomorphic-style-loader/StyleContext";

import configureStore from "./store/configureStore";

import ScrollToTop from "app/components/ScrollToTop";
import Routes from "./Routes";
import RootErrorBoundary from "app/components/RootErrorBoundary";
import { clientV2 } from "./apolloClient";
import initSentry from "./initSentry";

// Require global styles/defaults for use throughout the app
import "./styles/global.scss";
// global libs/lib styles
import "unfetch/polyfill";
import "react-select-plus/dist/react-select-plus.css";
import "react-table/react-table.css";
import "react-toggle/style.css";
import "react-quill/dist/quill.snow.css";
import "react-month-picker/css/month-picker.css";

initSentry();
  
global.UPLOADCARE_PUBLIC_KEY = UPLOADCARE_PUBLIC_KEY; // this is set from webpack

const store = configureStore();

const { ApolloProvider: ApolloProviderV2 } = ApolloV2;

const rootInstance = (
	<Provider store={store}>
		<ApolloProviderV2 client={clientV2}>
			<RootErrorBoundary>
				<BrowserRouter>
					{/*
						StyleContext.Provider is necessery because withStyles hoc does not work 
						if it is not wrapped in Stylecontext provider
					*/}

					<StyleContext.Provider value={{ insertCss: () => {} }}>
						<ScrollToTop>
							<Switch>
								<Route path="/" component={Routes} />
							</Switch>
						</ScrollToTop>
					</StyleContext.Provider>
				</BrowserRouter>
					
			</RootErrorBoundary>
		</ApolloProviderV2>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(rootInstance);

if (module.hot) {
	module.hot.accept();
}
