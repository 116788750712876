// @ts-strict-ignore
const initialState = {
    isUploading: false
};

export default (state = initialState, action) => {
    switch(action.type) {
        case 'START_UPLOAD':
            return {
                ...state,
                isUploading: true
            };
        case 'FINISH_UPLOAD': {
            return {
                ...state,
                isUploading: false
            };
        }
        default:
            return state;
    }
}
