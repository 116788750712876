// @ts-nocheck
import JWT from "jsonwebtoken";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import constants from "./constants";

// Flag (Singleton)
let initialized = false;

export const amplitudeInit = () : void => {
	if (process.env.REACT_APP_DOMAIN === constants.PROD_REACT_APP_DOMAIN && !initialized) {
		amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, {
			defaultTracking: {
				sessions: true
			},
			sampleRate: 0.5
		});

		amplitude.add(
			sessionReplayPlugin({
				captureClickTracking: true, // Capture click events
				captureInputTracking: true, // Track input interactions
				maskSensitiveInputs: true, // Mask input values for privacy
				sampleRate: 0.5, // Record 50% of sessions (adjust based on your needs),
				forceSessionTracking: true // Enable capture of Session Start and Session End events
			})
		);

		initialized = true;
	} else if (process.env.REACT_APP_DOMAIN === constants.QA_REACT_APP_DOMAIN && !initialized) {
		amplitude.init(process.env.REACT_APP_QA_AMPLITUDE_KEY, {
			defaultTracking: {
				sessions: true
			},
			sampleRate: 0.5
		});

		initialized = true;
	}
};

interface trackEventInput {
	event: string;
	eventOptions?: object;
}

const trackEvent = (input: trackEventInput) : void => {
	const { event, eventOptions } = input;
	if (!initialized) return;

	const jwt = localStorage.getItem("dcbyte-jwt");

	const user = JWT.decode(jwt);

	if (user) {
		const { publicId, email, roles } = user;

		amplitude.setDeviceId(publicId);
		amplitude.setUserId(publicId);
		
		// Use the Identify API to set user properties
		const identifyObj = new amplitude.Identify();

		if (email) {
			identifyObj.set("email", email);
		}

		if (user?.company?.name) {
			identifyObj.set("company", user.company.name);
		}

		if (roles) {
			identifyObj.set("roles", roles);
		}

		amplitude.identify(identifyObj);
	}

	amplitude.track(event, eventOptions);
};

export default trackEvent;
